import { gql } from '@apollo/client';
import FragmentArticleData from './FRAGMENT_ARTICLE_DATA';
import FragmentGalleryData from './FRAGMENT_GALLERY_DATA';
import FragmentVideoData from './FRAGMENT_VIDEO_DATA';
import FragmentGalleryFolderData from './FRAGMENT_GALLERY_FOLDER_DATA';

export default gql`
	${FragmentArticleData}
	${FragmentGalleryData}
	${FragmentVideoData}
	${FragmentGalleryFolderData}
	query CATEGORY_BY_KEY($key: String!, $edition: EDITIONKEY!) {
		categoryByKey(key: $key) {
			displayName(edition: $edition)
			title
			uri(edition: $edition)
			pageMetaData(edition: $edition) {
				canonicalUrl
				defaultUrl
				redirectUrl
				hreflang {
					edition
					uri
				}
			}
			page(edition: $edition) {
				id
				name
				metaTitleOverride
				metaDescriptionOverride
				layout {
					adKeywords
					sections {
						id
						sectionType
						widgets {
							id
							name
							timestampsEnabled
							authorBylineEnabled
							header
							headerImage {
								id
								title
								uri
							}
							isCurated
							criteria {
								contentType
								category(edition: $edition) {
									displayName(edition: $edition)
									key
								}
								dataType
								videoFeedId
							}
							contentList(edition: $edition, excludeBranded: true) {
								displayTitle
								displayImage(edition: $edition) {
									id
									title
									uri
									sourceWidth
									sourceHeight
								}
								linkType
								reference {
									id
									uri(edition: $edition)
									...ArticleData
									...GalleryData
									...VideoData
									...GalleryFolderData
								}
							}
							embedHtml
							widgetType
							widgetTemplate
							brandedTextOverride
							advertiserLogoOverride
							widgetAdKeyword
							brandingType
							additionalContentLink {
								reference {
									uri(edition: $edition)
									title
								}
							}
							coverflowBackgroundImage {
								id
								uri
							}
							livestream(edition: $edition) {
								streamTitle
								akamaiStreamUrl
								autoPlayToggle
								graphicInput {
									uri
								}
								streamDescription
							}
						}
					}
					contentFeedEnabled
				}
			}
		}
	}
`;
