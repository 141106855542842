import { queryError } from '@/utils/errorHandling';

/**
 * @const noIndexCategories
 * @type {Array}
 * @description array of categories (in all lowercase) that should always have a noindex robots meta tag
 */
const noIndexCategories = ['pca_portrait_studio_info'];

export const checkInvalidCategory = (category, categoryByKey) => {
	if (!categoryByKey)
		throw queryError({
			category,
			message: `category "${category}" not found`,
			pageType: 'Category Landing'
		});
}

/**
 * @function checkInvalidPageNumber
 * @param {String} category - from url param
 * @param {String} pageNum
 */
export const checkInvalidPageNumber = (category, pageNum) => {
	if (typeof pageNum === 'string' && isNaN(parseInt(pageNum))) {
		throw queryError({
			category,
			message: `Invalid page number: ${pageNum}`,
			pageType: 'Category Landing'
		});
	}
};

/**
 * @function checkAddNoIndex
 * @description tests whether to add a noindex meta tag
 * @param {String} categoryKey
 * @param {String|Number} pageNum
 * @returns {Boolean}
 */
export const shouldAddNoIndex = (categoryKey, pageNum) => {
	const notPageOne = pageNum && parseInt(pageNum) > 1;
	const isNoIndexCategory = noIndexCategories.includes(categoryKey);

	return notPageOne || isNoIndexCategory;
};
