/**
 * @function queryError
 * @description creates an object to be used for throwing a query error
 * @param {Object} [options] - pass in custom values with this object
 * @param {String} [options.category] - if the error relates to a category that is not found, category name can be included
 * @param {String} [options.id] - (articleId, videoId, showId)
 * @param {String} [options.message] - message to be sent with thrown error object
 * @param {String} [options.pageType] - type of page (adp, vdp shows)
 * @param {String} [options.redirectUrl]
 * @param {Number} [options.status] - HTML status code for response
 * @returns {Object}
 */
export const queryError = (options) => ({
	category: options?.category,
	id: options?.id || '',
	message: options?.message || 'Page Not Found',
	name: 'Query Error',
	pageType: options?.pageType || '',
	redirectUrl: options?.redirectUrl,
	status: options?.status || 404,
	type: 'queryException'
});
