import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import MetaTags from '@/components/meta-tags/MetaTags';
import LandingPage from '@/components/pages/landing/LandingPage';
import { MpsDataConfig } from '@/components/mps/MpsConfig';
import CATEGORY_BY_KEY from '@/graphql/queries/CATEGORY_BY_KEY';
import { usePageContext } from '@/components/layout/page/PageContext';
import HrefLang from '@/components/i18n/hreflang/HrefLang';
import {
	checkInvalidCategory,
	checkInvalidPageNumber,
	shouldAddNoIndex
} from './categoryLandingHelpers';
import { queryError } from '@/utils/errorHandling';

// configure static page config data
const analyticsContentType = 'news'; // 'news' or 'shows'
const analyticsPageType = 'landing'; // for window.dataLayer / pageview tracking
const className = 'category-landing';
const contentTypes = ['ARTICLE', 'GALLERY', 'VIDEO'];
const dataHook = 'category-landing';
const limit = 25; // content feed items per page
const location = 'category-landing'; // used for analytics in WidgetTrending
const siteSection = 'category'; // for window.dataLayer / pageview tracking

/**
 * @function CategoryLanding
 * @description provides a Category Landing Page component
 * @returns {React.ReactElement}
 */
const CategoryLanding = () => {
	const { category, pageNum, uppercaseEdition, translations } = usePageContext();

	checkInvalidPageNumber(category, pageNum);

	// API request for Page / widget data
	const categoryKey = category.toLowerCase();
	const variables = { edition: uppercaseEdition, key: categoryKey };
	const { data, error } = useQuery(CATEGORY_BY_KEY, { variables });

	if (error) console.error(error.message);

	if (data) checkInvalidCategory(category, data?.categoryByKey);

	// configure layout
	const layout = data?.categoryByKey?.page?.layout;

	// configure dynamic page config data
	const analyticsCategory = categoryKey?.replace('_', '-');
	const basePath = `news/${categoryKey}`;
	const showContentFeed = typeof layout === 'undefined' || !layout || layout?.contentFeedEnabled;

	// configure metaTags
	const displayName = data?.categoryByKey?.displayName || data?.categoryByKey?.title;
	const title =
		data?.categoryByKey?.page?.metaTitleOverride ||
		`${displayName} ${translations.NEWS_PICS_VIDEOS || ''}`;
	const description =
		data?.categoryByKey?.page?.metaDescriptionOverride ||
		`${translations.LATEST_ABOUT_TAGLINE || ''} ${displayName}`;
	const pageMetaData = data?.categoryByKey?.pageMetaData;

	if (pageMetaData?.redirectUrl) {
		throw queryError({
			message: 'Page Moved Permanently',
			pageType: 'CategoryLanding',
			redirectUrl: pageMetaData.redirectUrl,
			status: 301
		});
	}

	const metaTagData = displayName
		? {
				title,
				description,
				ogType: 'website',
				options: { ogTags: true, ogImage: false, twitter: true, hasPagination: true }
		  }
		: {};

	// Add noindex metatag if it should be added
	if (shouldAddNoIndex(categoryKey, pageNum)) {
		metaTagData.customNameTags = { robots: 'noindex' };
	}

	// build pageConfigData object
	const pageConfigData = {
		analyticsCategory,
		analyticsContentType,
		analyticsPageType,
		basePath,
		categoryKey,
		className,
		contentTypes,
		dataHook,
		limit,
		location,
		metaTitle: title,
		showContentFeed,
		siteSection,
		title: displayName
	};

	return (
		<Fragment>
			{pageMetaData && (
				<HrefLang uri={pageMetaData?.defaultUrl} hrefLang={pageMetaData?.hreflang} />
			)}
			<MpsDataConfig topCategory={category} pageAdKeyword={layout?.adKeywords} />
			{metaTagData?.description && <MetaTags {...metaTagData} />}
			<LandingPage
				layout={layout}
				pageConfigData={pageConfigData}
				pageId={data?.categoryByKey?.page?.id}
			/>
		</Fragment>
	);
};

CategoryLanding.displayName = 'CategoryLanding';

export default CategoryLanding;
